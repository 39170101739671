<template>
  <b-modal
    id="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯管理者
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 狀態 -->
        <b-form-group label-for="status">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                狀態
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="userData.state"
            :options="stateOptions"
            :clearable="false"
            :reduce="option => option.value"
            placeholder="請選擇狀態"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 管理者名稱 -->
        <validation-provider
          #default="{ errors }"
          name="管理者名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                管理者名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="userData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="editDataItem.name ? editDataItem.name : '請輸入管理者名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 管理者帳號 -->
        <validation-provider
          #default="{ errors }"
          name="管理者帳號"
          rules="required"
        >
          <b-form-group label-for="account">
            <template #label>
              <label class="mb-0">
                管理者帳號
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="account"
              v-model="userData.account"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="editDataItem.account ? editDataItem.account : '請輸入管理者帳號'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 變更密碼(編輯模式) -->
        <validation-provider
          #default="{ errors }"
          name="password"
        >

          <b-form-group label-for="userData-password">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  變更密碼
                </label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="密碼產生器"
                    class="d-flex align-items-center actions-link-btn"
                    @click="() => { $refs.randomGenerator.getData() }"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>
            </template>

            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="userData-password"
                v-model="userData.password"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="userData-password"
                placeholder="請輸入管理者密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword"
                />
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <random-generator
          ref="randomGenerator"
          department-explorer-id="randomGenerator"
          department-explorer-type="modal"
          :is-hiden-emit="false"
          :use-callback-data="true"
          @call-back-data="(item) => userData.password = item"
        />
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, BImg, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import RandomGenerator from '@/layouts/components/Modal/random-modal/RandomModal.vue'
import { useUserList, useUserSetting } from '../useUser'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // BFormSpinbutton,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    RandomGenerator,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    editDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      passwordFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    // 顯示密碼/隱藏密碼
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.reseUserData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '管理者名稱',
        account: '管理者帳號',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.userData[Object.keys(requiredFields)[i]] === null || this.userData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true

      const submitUpdate = () => {
        this.setUserUpdate({
          user_id: this.editDataItem.id,
          data: {
            ...this.userData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.reseUserData()
            this.$nextTick(() => {
              this.$bvModal.hide('edit-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }

      if (this.userData.password) {
        this.setUserPasswordUpdate({
          user_id: this.editDataItem.id,
          data: {
            newPassword: this.userData.password,
          },
        })
          .then(() => {
            submitUpdate()
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else submitUpdate()
    },

    // (獲取)資料
    getData() {
      // syncObject
      const resolveData = {
        ...this.syncObject(this.blankUserData, this.editDataItem),
      }
      this.userData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setUserUpdate,
      setUserPasswordUpdate,
    } = useUserList()

    const {
      stateOptions,
      departmentsOptions,
      syncObject,
    } = useUserSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankUserData = {
      name: null,
      account: null,
      password: null,
      state: false,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const reseUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(reseUserData)

    return {
      isBusy,

      userData,
      stateOptions,
      departmentsOptions,
      blankUserData,
      setUserUpdate,
      setUserPasswordUpdate,

      reseUserData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
